import React, { useState, useEffect, Fragment } from 'react'
import './Offers.scss'
import OfferCard from './cards/OfferCard'
import Filter from './buttons/filter'

import {getValues} from './utility/offerFunctions'

const Offers = (props) => {
    const {quickgenId, liveActionShots} = props;
    const [contentType, setContentType] = useState('');
    const [model, setModel] = useState('');
    const [specials, setSpecials] = useState({});
    const [allSpecials, setAllSpecials] = useState([]);
    const [filters, setFilters] = useState([]);
    const [placeholder, setPlaceholderValue] = useState(false);

    useEffect(() => {
        return getValues(quickgenId, setSpecials, setFilters, setAllSpecials, setPlaceholderValue, setContentType, setModel);
    }, [])

    const filterAllSpecials = (allSpecials, model) => {
        let newArr = allSpecials.filter(item => item.model === model)
        return (
            <Fragment>
                {newArr.map((offer, index) => <OfferCard cpo={props.data.cpoOffers} placeholder={placeholder} key={index} offer={offer} liveActionShots={liveActionShots} setContentType={setContentType}/>)}
            </Fragment>
        )
    }


    const toggleMobileFilters = (e) => {
        let elem = document.getElementById('filters-cont')
        elem.classList.toggle('expand-filters')

        if (elem.classList.contains('expand-filters')) {
            return e.currentTarget.innerHTML = 'Hide Filters <span class="symbol">-</span>'
        }
        return e.currentTarget.innerHTML = 'Show Filters <span class="symbol">+</span>'
    }

    while(!contentType && Object.keys(specials).length > 0 && allSpecials.length > 0 && model === '') {
        return <div></div>
    }

    return (
        <div className="offers__container">
            <div className="filters">
                <button id="mobile-expand-btn" className="mobile-expand" onClick={(e) => toggleMobileFilters(e)}>Show Filters <span className="symbol">+</span></button>
                <div id="filters-cont" className="filters-cont">            
                    <Filter filterType='All' contentType={contentType} filters={filters} setContentType={setContentType} setModel={setModel}/>
                    {filters.length > 0 ? filters.map((filter, index) => <Filter key={index} contentType={contentType} model={model} filterType={filter} setModel={setModel} setContentType={setContentType} />) : ''}
                </div>
            </div>
            <div className="cards">
                {contentType === 'All' ?
                    <Fragment>
                        {allSpecials.length > 0 ? allSpecials.map((offer, index) => <OfferCard cpo={props.data.cpoOffers} placeholder={placeholder} key={index} offer={offer} liveActionShots={liveActionShots} setContentType={setContentType}/>) : ''}
                    </Fragment>
                : contentType === 'Model' ?
                    <Fragment>
                        {filterAllSpecials(allSpecials, model)}
                    </Fragment>
                : (contentType !== 'All' && contentType !== 'Dealer' && contentType !== '') ?
                    <Fragment>
                        {Object.keys(specials).length > 0 ? specials[contentType].map((offer, index) => <OfferCard key={index} cpo={props.data.cpoOffers} offer={offer} liveActionShots={liveActionShots} setContentType={setContentType}/>) : ''}
                    </Fragment>
                : ''}
            </div>
        </div>
    )
}



export default Offers